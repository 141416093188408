import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import ViewUniversityDetails from "../ViewUniversityDetails";
import {
  fetchUniversityById,
  updateUniversityById,
} from "../university.service";
import { UniversityUpdateValidationSchema } from "./university.schema";

const UniversityDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [universityDetails, setUniversityDetails] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  // Enable Edit Mode
  const toggleEditMode = () => {
    setIsEditing(true);
  };

  // Disable Edit Mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    resetForm();
  };

  // Fetch University's Details
  const fetchUniversityDetails = useCallback(async () => {
    setLoading(true);
    const response = await fetchUniversityById(id);
    if (response?.status === 200) {
      setUniversityDetails(response?.data);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchUniversityDetails();
  }, [id, fetchUniversityDetails]);

  const initialValues = {
    university_name: universityDetails?.name || "",
    university_email: universityDetails?.email || "",
    university_phoneNumber:
      universityDetails?.phoneNumber?.split("+")?.[1] || "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const newData: any = {
        name: values?.university_name,
        email: values?.university_email,
        phoneNumber: `+${values?.university_phoneNumber}`,
      };

      //  Remove the Duplicate Values
      const updatedValues: any = {};

      for (const key in newData) {
        if (newData[key]?.trim() !== universityDetails[key]) {
          updatedValues[key] = newData[key];
        }
      }

      const updatedUniversity = await updateUniversityById(
        universityDetails?._id,
        updatedValues
      );
      if (updatedUniversity?.success) {
        setUniversityDetails(updatedUniversity?.data);
        toast.success(
          updatedUniversity?.message ||
            "University details have been successfully updated."
        );
      } else {
        toast.error("Oops, something went wrong. Please try again later.");
      }

      setIsEditing(false);
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: UniversityUpdateValidationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {isEditing ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
              <p className="mb-4 text-lg font-semibold text-indigo-900">
                Update University Information
              </p>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="university_name"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    University Name:
                  </label>
                  <input
                    type="text"
                    id="university_name"
                    name="university_name"
                    placeholder="Enter Username"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.university_name}
                  />
                  {errors?.university_name && touched?.university_name && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.university_name as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="university_email"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    University Email:
                  </label>
                  <input
                    type="email"
                    id="university_email"
                    name="university_email"
                    placeholder="Enter Email Address"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.university_email}
                  />
                  {errors?.university_email && touched?.university_email && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.university_email as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="university_phoneNumber"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    University Phone:
                  </label>
                  <div className="w-full">
                    <PhoneInput
                      country="de"
                      disableDropdown={false}
                      countryCodeEditable={false}
                      enableSearch
                      searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                      // disableSearchIcon
                      placeholder="Enter Phone Number"
                      value={values?.university_phoneNumber}
                      onChange={(phoneNumber: string) => {
                        setFieldValue("university_phoneNumber", phoneNumber);
                      }}
                      onBlur={() => setFieldTouched("university_phoneNumber")}
                    />
                  </div>
                  {touched?.university_phoneNumber &&
                    errors?.university_phoneNumber && (
                      <div className="text-sm text-red-500">
                        {errors?.university_phoneNumber as string}
                      </div>
                    )}
                </div>
              </div>
              <hr className="my-6 border-t border-gray-300" />
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Update Details
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <ViewUniversityDetails
            universityDetails={universityDetails}
            toggleEditMode={toggleEditMode}
          />
        </>
      )}
    </>
  );
};

export default UniversityDetails;
