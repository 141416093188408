import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/high-res.css";
import "./App.css";
import { getToken } from "utils/services/helpers";
import { useEffect } from "react";
const publicRoutes = [
  "/auth/sign-in",
  "/auth/forgot-password",
  "/auth/reset-password",
];

const App = () => {
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!publicRoutes.includes(window.location.pathname) && !token) {
      return navigate("/auth/sign-in");
    }
  }, [token, navigate]);

  const authRoutes = (
    <>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path="*" element={<Navigate to="/auth/sign-in" replace />} />
    </>
  );

  const adminRoutes = (
    <>
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="/" element={<Navigate to="/admin/default" replace />} />
      <Route path="*" element={<Navigate to="/admin/default" replace />} />
    </>
  );

  return <Routes>{token ? adminRoutes : authRoutes}</Routes>;
};

export default App;
