import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { TiArrowBack } from "react-icons/ti";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { createUniversity } from "../university.service";
import { UniversityCreateSchema } from "./university.schema";

const CreateNewUniversity = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    university_name: "",
    university_email: "",
    university_phoneNumber: "",
    university_password: "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const createUniversityPayload: any = {
        name: values?.university_name,
        email: values?.university_email,
        phoneNumber: `+${values?.university_phoneNumber}`,
        password: values?.university_password,
        role: "university",
      };
      await createUniversity(createUniversityPayload);
      resetForm();
      navigate("/admin/university-management");
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: UniversityCreateSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
          <div className="mb-9 flex items-center justify-between rounded-lg border-b-2 border-gray-200 bg-gray-200 p-4 px-6 py-3">
            <p className="text-lg font-semibold text-indigo-900">
              Create a University by filling the following details
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="mb-4">
              <label
                htmlFor="university_name"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Name:
              </label>
              <input
                type="text"
                id="university_name"
                name="university_name"
                placeholder="Enter University Name"
                className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.university_name}
              />
              {errors?.university_name && touched?.university_name && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.university_name as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="university_email"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Email:
              </label>
              <input
                type="email"
                id="university_email"
                name="university_email"
                placeholder="Enter Email Address"
                className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.university_email}
              />
              {errors?.university_email && touched?.university_email && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.university_email as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="university_name"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                Password:
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="university_password"
                  name="university_password"
                  placeholder="******"
                  className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.university_password}
                />
                <span
                  className="absolute right-5 top-1/2 -translate-y-1/2 transform cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
                </span>
              </div>
              {errors?.university_password && touched?.university_password && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.university_password as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="university_phoneNumber"
                className="mb-2 block text-sm font-medium text-indigo-900"
              >
                University Phone:
              </label>
              <div className="w-full">
                <PhoneInput
                  country="de"
                  disableDropdown={false}
                  countryCodeEditable={false}
                  enableSearch
                  searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                  // disableSearchIcon
                  placeholder="Enter Phone Number"
                  value={values?.university_phoneNumber}
                  onChange={(phoneNumber: string) => {
                    setFieldValue("university_phoneNumber", phoneNumber);
                  }}
                  onBlur={() => setFieldTouched("university_phoneNumber")}
                />
              </div>
              {touched?.university_phoneNumber &&
                errors?.university_phoneNumber && (
                  <div className="text-sm text-red-500">
                    {errors?.university_phoneNumber as string}
                  </div>
                )}
            </div>
          </div>
          <hr className="my-6 border-t border-gray-300" />
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              onClick={() => navigate("/admin/university-management")}
            >
              <span>Back</span>
              <TiArrowBack className="h-4 w-4" />
            </button>
            <button
              type="submit"
              className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Create University
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateNewUniversity;
