import * as Yup from "yup";
import { ValidationMessages } from "./signin.constants";
import { emailRegex } from "../../../utils/constants";

export const SignInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required(ValidationMessages.emailAddress.required),
  password: Yup.string()
    .trim()
    .required(ValidationMessages.passwordField.required)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
      ValidationMessages.passwordField.invalid
    ),
});
