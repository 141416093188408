export const ValidationMessages = {
  emailAddress: {
    required: "Email is required",
    invalid: "Invalid email address",
  },
  passwordField: {
    required: "Password is required",
    invalid:
      "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#.",
  },
};
