import * as Yup from "yup";
import { ValidationMessages } from "./reset.constants";

export const ResetFormSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required(ValidationMessages.password.required)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,14}$/,
      ValidationMessages.password.invalid
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Password does not match"),
});
