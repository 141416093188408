import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import ViewStudentDetails from "../ViewStudentDetails";
import {
  fetchStudentById,
  fetchStudentDashboardDataById,
  updateStudentById,
} from "../student.service";
import { StudentUpdateValidationSchema } from "./student.schema";

const StudentDetails = () => {
  const { studentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [studentData, setStudentData] = useState(null);
  const [studentDashboardData, setStudentDashboardData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  // Enable Edit Mode
  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Disable Edit Mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    resetForm();
  };

  // Fetch Student's Details
  const fetchStudentDetails = useCallback(async () => {
    setLoading(true);

    const response = await fetchStudentById(studentId);
    if (response?.success) {
      setStudentData(response?.data);
      if (response?.data?._id) {
        const studentDashboardDataResponse =
          await fetchStudentDashboardDataById(response?.data?._id);
        if (studentDashboardDataResponse?.data?.success) {
          setStudentDashboardData(studentDashboardDataResponse?.data?.data);
        }
      }
    } else {
      toast.error(response?.message);
    }
    setLoading(false);
  }, [studentId]);

  useEffect(() => {
    fetchStudentDetails();
  }, [studentId, fetchStudentDetails]);

  const initialValues = {
    studentId: studentData?.studentId || "",
    student_name: studentData?.name || "",
    student_email: studentData?.email || "",
    student_phoneNumber: studentData?.phoneNumber?.split("+")?.[1] || "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const newData: any = {
        studentId: values?.studentId.toUpperCase(),
        name: values?.student_name,
        email: values?.student_email,
        phoneNumber: `+${values?.student_phoneNumber}`,
      };

      //  Remove the Duplicate Values
      const updatedValues: any = {};

      for (const key in newData) {
        if (newData[key]?.trim() !== studentData[key]) {
          updatedValues[key] = newData[key];
        }
      }

      const updatedStudentDetails = await updateStudentById(
        studentData?.studentId,
        updatedValues
      );
      if (updatedValues?.data?.studentId) {
        navigate(
          `/admin/student-management/${updatedStudentDetails?.data?.studentId}`
        );
      }
      setStudentData(updatedStudentDetails?.data);
      setIsEditing(false);
      toast.success(
        updatedStudentDetails?.message ||
          `${updatedStudentDetails?.data?.name} updated successfully`
      );
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: StudentUpdateValidationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {isEditing ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
              <p className="mb-4 text-lg font-semibold text-indigo-900">
                Update Student Information
              </p>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="studentId"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Student ID:
                  </label>
                  <input
                    type="text"
                    id="studentId"
                    name="studentId"
                    placeholder="Enter Student ID"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.studentId}
                  />
                  {errors?.studentId && touched?.studentId && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.studentId as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="student_name"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Student Name:
                  </label>
                  <input
                    type="text"
                    id="student_name"
                    name="student_name"
                    placeholder="Enter Username"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.student_name}
                  />
                  {errors?.student_name && touched?.student_name && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.student_name as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="student_email"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Email address:
                  </label>
                  <input
                    type="email"
                    id="student_email"
                    name="student_email"
                    placeholder="Enter Email Address"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.student_email}
                  />
                  {errors?.student_email && touched?.student_email && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.student_email as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="student_phoneNumber"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Student Phone:
                  </label>
                  <div className="w-full">
                    <PhoneInput
                      country="de"
                      disableDropdown={false}
                      countryCodeEditable={false}
                      enableSearch
                      searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                      // disableSearchIcon
                      placeholder="Enter Phone Number"
                      value={values?.student_phoneNumber}
                      onChange={(phoneNumber: string) => {
                        setFieldValue("student_phoneNumber", phoneNumber);
                      }}
                      onBlur={() => setFieldTouched("student_phoneNumber")}
                    />
                  </div>
                  {touched?.student_phoneNumber &&
                    errors?.student_phoneNumber && (
                      <div className="text-sm text-red-500">
                        {errors?.student_phoneNumber as string}
                      </div>
                    )}
                </div>
              </div>
              <hr className="my-6 border-t border-gray-300" />
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Update Details
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <ViewStudentDetails
            student={studentData}
            studentDashboardData={studentDashboardData}
            toggleEditMode={handleEditClick}
          />
        </>
      )}
    </>
  );
};

export default StudentDetails;
