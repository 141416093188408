import { brandLogo } from "assets";
import axios from "axios";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { ForgotPasswordSchema } from "./forgot.schema";
import { ForgotPasswordTypes } from "./forgot.types";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_API_URL}/auth/forgot-password`,
        values
      );
      const { message } = response?.data;
      toast.success(message || "Reset-Password Link Send Successfully");
      navigate("/admin/sign-in");
      resetForm();
    } catch (error: any) {
      const { message } = error?.response?.data?.message;
      toast.error(message || "No User Found With This EmailID");
    }
  };

  const initialValues: ForgotPasswordTypes = {
    email: "",
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ForgotPasswordSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2"
      onSubmit={handleSubmit}
    >
      <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
        <div className="form-container lg mx-auto w-full p-6 md:w-[500px]">
          <div className="mb-5 flex items-center justify-center text-center font-poppins text-[22px] font-bold uppercase text-navy-700">
            <img src={brandLogo} alt="logo" className="w-25 align-middle" />
          </div>
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700">
            Forgot Password?
          </h4>
          <p className="mb-9 ml-1 text-center text-base font-normal text-gray-600">
            Don’t worry! It happens. Please enter the email address associated
            with your account
          </p>
          <div className="mb-3">
            <label htmlFor="email" className="text-sm text-navy-700 ">
              Email*
            </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Enter Your Email"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/* Display email_address validation error */}
            {errors?.email && touched?.email && (
              <div className="text-sm text-red-500 ">{errors?.email}</div>
            )}
            <p className="my-3 text-sm text-blue-800">
              <Link to="/auth/sign-in">Back to Sign-in &rarr;</Link>
            </p>
          </div>
          <button
            className="linear theme-bg mt-2 flex w-full flex-row items-center justify-center rounded-xl py-[12px] text-center text-base font-medium text-white transition duration-200"
            type="submit"
          >
            Submit
          </button>{" "}
        </div>
      </div>
    </form>
  );
};

export default ForgotPassword;
