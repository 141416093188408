const LoaderUI = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
      <div className="loader" />
    </div>
  );
};

export default LoaderUI;
