import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function useAuthContext() {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  const setLoggedInUser = async (userData: any) => {
    setCurrentUser(userData);
    localStorage.setItem("currentUser", JSON.stringify(userData));
  };

  const logout = () => {
    setCurrentUser(null);
    localStorage.clear();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  return { currentUser, setLoggedInUser, logout };
}

export default useAuthContext;
