import { emailRegex } from "utils/constants";
import { removeFirstMatch } from "utils/services/helpers";
import * as Yup from "yup";

export const StudentUpdateValidationSchema = Yup.object().shape({
  studentId: Yup.string()
    .required("Student ID is required")
    .matches(/^[a-zA-Z0-9\s]+$/, "Please enter only digits and letters")
    .matches(/^[a-zA-Z0-9]{1,10}$/, "Student ID should not be greater than 10")
    .test("contains-digits", "At least one digit is required", (value) => {
      return /\d/.test(value);
    })
    .test("contains-letters", "At least one letter is required", (value) => {
      return /[a-zA-Z]/.test(value);
    }),
  student_name: Yup.string()
    .min(2, "Minimum 2 Characters")
    .max(20, "Maximum 20 Characters")
    .required("Student Name is required"),
  student_email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is Required"),
  student_phoneNumber: Yup.string()
    .required("Mobile number is required")
    .test("checkEmptyValue", "Mobile number is required", function (value) {
      const { countryCode } = this.parent;
      return `+${value}` !== countryCode;
    })
    .test("checkMinNumber", "Enter Valid Mobile Number", function (value) {
      const { countryCode } = this.parent;
      return removeFirstMatch(value, countryCode?.split("+")?.[1])?.length >= 4;
    })
    .matches(/^\d{1,14}$/, "Invalid phone number"),
});
