import { dummyAvatar } from "assets";
import ModalView from "components/modal";
import WidgetCard from "components/widgetcard";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { RiPencilLine } from "react-icons/ri";
import {
  ICommentList,
  IInternationalDocs,
  IMainFormData,
  IStatuses,
  IStudent,
  otherMainFormData,
} from "../student.types";

const ViewStudentDetails = ({
  student,
  toggleEditMode,
  studentDashboardData,
}: {
  student: IStudent;
  toggleEditMode: () => void;
  studentDashboardData: any;
}) => {
  const [isShowCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedComments, setSelectedComments] = useState(null);

  const [comments, setComments] = useState(null);

  const handleCommentsClick = (commentList: ICommentList) => {
    setComments(commentList);
  };

  // Comments : Student Verification [ OPEN ]
  const openStudentVerificationCommentsModal = (comments: string[]) => {
    setSelectedComments(comments);
    setShowCommentsModal(true);
  };

  // Comments : Student Verification [ CLOSE ]
  const closeStudentVerificationCommentsModal = () => {
    setShowCommentsModal(false);
    setSelectedComments(null);
  };

  // Extract the Main Form Data
  const mainFormStepData = studentDashboardData?.find(
    (step: IMainFormData) => step.stepName === "Main form"
  );

  // Extract the Other Steps Data
  const otherMainFormData = studentDashboardData?.filter(
    (step: otherMainFormData) => step.stepName !== "Main form"
  );

  return (
    <>
      {student ? (
        <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="bg-gray-200 p-6">
            <div className="flex items-center justify-between">
              <h1 className="text-xl font-semibold">
                {student?.name || "Student Details"}
              </h1>
            </div>
          </div>
          <div className="container mx-auto p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img
                  className="mr-4 h-16 w-16 cursor-not-allowed rounded-full object-cover"
                  src={student?.profilePic || dummyAvatar}
                  alt="Student"
                />
                <div>
                  <h2 className="flex items-center gap-1 text-xl font-bold text-gray-800">
                    {student?.name || (
                      <span className="text-red-500">No Data Found</span>
                    )}
                    <RiPencilLine
                      className="cursor-pointer text-green-500"
                      onClick={toggleEditMode}
                    />
                  </h2>
                  <p className="text-sm text-gray-600">
                    {student?.role || (
                      <span className="text-red-500">No Data Found</span>
                    )}
                  </p>
                  <p className="text-sm text-gray-600 sm:hidden">
                    {student?.studentId || (
                      <span className="text-red-500">No Data Found</span>
                    )}
                  </p>
                </div>
              </div>
              <p className="hidden text-sm text-gray-700 sm:inline-block">
                ID:{" "}
                {student?.studentId || (
                  <span className="text-red-500">No Data Found</span>
                )}
              </p>
            </div>

            <div className="mt-4 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
              <WidgetCard
                label="Email:"
                data={
                  student?.email && (
                    <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                      {student?.email}{" "}
                      {student?.isEmailVerified && (
                        <FaCheckCircle className="text-green-500" />
                      )}
                    </span>
                  )
                }
              />
              <WidgetCard
                label="Phone Number:"
                data={
                  student?.phoneNumber && (
                    <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                      {student?.phoneNumber}{" "}
                      {student?.isPhoneNumberVerified && (
                        <FaCheckCircle className="text-green-500" />
                      )}
                    </span>
                  )
                }
              />
              <WidgetCard
                label="Street Address:"
                data={student?.streetAddress}
              />
              <WidgetCard label="City:" data={student?.city} />
              <WidgetCard label="Study:" data={student?.study} />
              <WidgetCard
                label="Visa Expiry Date:"
                data={
                  student?.visaExpiryDate &&
                  new Date(student?.visaExpiryDate).toLocaleDateString()
                }
              />
              <WidgetCard
                label="Visa Type"
                data={
                  (student?.isGermanStudyEntryVisa
                    ? "German Study Entry Visa"
                    : "") +
                  (student?.isGermanStudyEntryVisa && student?.is90daysFreeEntry
                    ? ", "
                    : "") +
                  (student?.is90daysFreeEntry
                    ? "90-days free entry allowance"
                    : "")
                }
              />
              <WidgetCard
                label="Approval by University:"
                data={student?.status}
              />
              <WidgetCard label="University:" data={student?.university} />
              <WidgetCard
                label="Signature:"
                data={
                  student?.signature && (
                    <div className="mt-4">
                      <img
                        className="h-32 w-auto rounded-md border border-gray-200"
                        src={student?.signature}
                        alt="Student's Signature"
                      />
                    </div>
                  )
                }
              />
            </div>

            <WidgetCard
              label="Student Documents:"
              data={
                <div className="mt-3 flex flex-row items-center justify-between lg:space-x-4">
                  {/* Visa Copy */}
                  {student?.entryVisaCopy && (
                    <a
                      href={student?.entryVisaCopy}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-500 hover:text-blue-700"
                    >
                      View Visa Copy
                    </a>
                  )}
                  {/* Financial Document */}
                  {student?.financialDoc && (
                    <a
                      href={student?.financialDoc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-500 hover:text-blue-700"
                    >
                      View Financial Document
                    </a>
                  )}
                  {/* Interna tional Documents */}
                  {Array.isArray(student?.internationalDocs) &&
                    student?.internationalDocs?.map(
                      (doc: IInternationalDocs) => (
                        <>
                          {doc?.marticulationLetter && (
                            <a
                              href={doc?.marticulationLetter}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm text-blue-500 hover:text-blue-700"
                            >
                              View Matriculation Letter
                            </a>
                          )}
                          {doc?.proofEntryToGermany && (
                            <a
                              href={doc?.proofEntryToGermany}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm text-blue-500 hover:text-blue-700"
                            >
                              Proof Entry To Germany
                            </a>
                          )}
                          {doc?.statusLetter && (
                            <a
                              href={doc?.statusLetter}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-sm text-blue-500 hover:text-blue-700"
                            >
                              Status Letter
                            </a>
                          )}
                        </>
                      )
                    )}
                </div>
              }
            />

            {studentDashboardData && (
              <>
                {otherMainFormData && (
                  <>
                    <div className="mt-8 text-left">
                      <h1 className="text-2xl font-bold text-gray-800 ">
                        Student Verification Detail:
                      </h1>
                      <div className="mb-4 mt-2 h-1 w-24 bg-blue-500"></div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="w-full table-auto border-collapse">
                        <thead>
                          <tr className="bg-gray-200">
                            <th className="px-4 py-2">Step Name</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {otherMainFormData?.map(
                            (step: otherMainFormData, index: number) => (
                              <tr
                                key={index}
                                className="border-b border-gray-200"
                              >
                                <td className="whitespace-nowrap border px-6 py-4">
                                  {step?.stepName}
                                </td>
                                <td className="whitespace-nowrap border px-6 py-4">
                                  {step?.status}
                                </td>
                                <td className="whitespace-wrap border px-6 py-4">
                                  {step?.comments?.length > 0 ? (
                                    <>
                                      <button
                                        onClick={() =>
                                          openStudentVerificationCommentsModal(
                                            step?.comments
                                          )
                                        }
                                        className="text-sm text-blue-600 hover:underline focus:outline-none"
                                      >
                                        View Comments
                                      </button>
                                    </>
                                  ) : (
                                    <span className="text-gray-600">
                                      No comments
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}

                {mainFormStepData.status && (
                  <>
                    <div className="mt-9 text-left">
                      <h1 className="text-2xl font-bold text-gray-800">
                        Main Form Details:
                      </h1>
                      <div className="mb-4 mt-2 h-1 w-24 bg-blue-500"></div>
                    </div>

                    <div className="overflow-x-auto">
                      <table className="w-full table-auto border-collapse">
                        <thead>
                          <tr className="bg-gray-200">
                            <th className="px-4 py-2">Step Name</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Comments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mainFormStepData?.status?.map(
                            (statusData: IStatuses, statusIndex: number) => {
                              const isStep7Or8 =
                                statusData?.step === 7 ||
                                statusData?.step === 8;
                              const commentsForStep =
                                mainFormStepData?.comments.find(
                                  (comment: any) =>
                                    comment?.stepNumber === statusData?.step
                                )?.comments || [];
                              return (
                                <tr
                                  key={statusIndex}
                                  className="border-b border-gray-200"
                                >
                                  <td className="whitespace-nowrap border px-6 py-4">
                                    {statusData?.label}
                                  </td>
                                  <td
                                    className={`whitespace-nowrap border px-6 py-4 ${
                                      isStep7Or8 ? "text-center" : ""
                                    }`}
                                    colSpan={isStep7Or8 ? 2 : 1}
                                  >
                                    {statusData?.status}
                                  </td>
                                  {!isStep7Or8 && (
                                    <td className="whitespace-nowrap border px-6 py-4">
                                      {commentsForStep?.length > 0 ? (
                                        <button
                                          onClick={() =>
                                            handleCommentsClick(commentsForStep)
                                          }
                                          className="text-sm text-blue-600 hover:underline focus:outline-none"
                                        >
                                          View Comments
                                        </button>
                                      ) : (
                                        <span className="text-gray-600">
                                          No comments
                                        </span>
                                      )}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="p-6 text-center">
            <p className="font-semibold text-red-500">No Data Found</p>
          </div>
        </div>
      )}

      <ModalView
        isOpen={isShowCommentsModal}
        onClose={closeStudentVerificationCommentsModal}
        showActions={false}
      >
        <div className="max-w-xl p-4">
          <ul>
            {selectedComments?.map((comment: any, index: number) => (
              <li
                key={index}
                className="list-inside list-disc text-sm text-gray-600"
              >
                {comment}
              </li>
            ))}
          </ul>
        </div>
      </ModalView>

      <ModalView
        isOpen={comments?.length > 0}
        onClose={() => setComments([])}
        showActions={false}
      >
        {comments?.length > 0 ? (
          <div className="max-w-xl p-4">
            <ul>
              {comments?.map((comment: ICommentList, index: number) => (
                <li
                  className="list-inside list-disc text-sm text-gray-600"
                  key={index}
                >
                  {comment?.text}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No Comments Available</p>
        )}
      </ModalView>
    </>
  );
};

export default ViewStudentDetails;
