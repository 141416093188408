import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { useParams } from "react-router-dom";
import ViewLawyerDetails from "../ViewLawyerDetails";
import { fetchLawyerById, updateLawyerById } from "../lawyer.service";
import { LawyerUpdateValidationSchema } from "./lawyer.schema";

const LawyerDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [lawyerDetails, setLawyerDetails] = useState(null);

  const [isEditing, setIsEditing] = useState(false);

  // Enable Edit Mode
  const toggleEditMode = () => {
    setIsEditing(true);
  };

  // Disable Edit Mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    resetForm();
  };

  // Fetch Lawyer's Details
  const fetchLawyerDetails = useCallback(async () => {
    setLoading(true);
    const response = await fetchLawyerById(id);
    if (response?.status === 200) {
      setLawyerDetails(response?.data);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    fetchLawyerDetails();
  }, [id, fetchLawyerDetails]);

  const initialValues = {
    lawyer_name: lawyerDetails?.name || "",
    lawyer_email: lawyerDetails?.email || "",
    lawyer_phoneNumber: lawyerDetails?.phoneNumber?.split("+")?.[1] || "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const newData: any = {
        name: values?.lawyer_name,
        email: values?.lawyer_email,
        phoneNumber: `+${values?.lawyer_phoneNumber}`,
      };

      const updatedValues: any = {};

      for (const key in newData) {
        if (newData[key]?.trim() !== lawyerDetails[key]) {
          updatedValues[key] = newData[key];
        }
      }

      const updatedLawyer = await updateLawyerById(
        lawyerDetails?._id,
        updatedValues
      );
      if (updatedLawyer?.success) {
        setLawyerDetails(updatedLawyer?.data);
        toast.success(
          updatedLawyer?.message ||
            "Lawyer details have been successfully updated."
        );
      } else {
        toast.error("Oops, something went wrong. Please try again later.");
      }

      setIsEditing(false);
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    validationSchema: LawyerUpdateValidationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      {isEditing ? (
        <>
          <form onSubmit={handleSubmit}>
            <div className="w-full flex-auto rounded-lg bg-white p-6 shadow-lg">
              <p className="mb-4 text-lg font-semibold text-indigo-900">
                Update Lawyer Information
              </p>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="mb-4">
                  <label
                    htmlFor="lawyer_name"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Lawyer Name:
                  </label>
                  <input
                    type="text"
                    id="lawyer_name"
                    name="lawyer_name"
                    placeholder="Enter Username"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.lawyer_name}
                  />
                  {errors?.lawyer_name && touched?.lawyer_name && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.lawyer_name as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lawyer_email"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Lawyer Email:
                  </label>
                  <input
                    type="email"
                    id="lawyer_email"
                    name="lawyer_email"
                    placeholder="Enter Email Address"
                    className="focus:shadow-outline block w-full rounded-lg border-gray-300 bg-gray-100 px-4 py-2 focus:border-blue-500 focus:outline-none"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.lawyer_email}
                  />
                  {errors?.lawyer_email && touched?.lawyer_email && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.lawyer_email as string}
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lawyer_phoneNumber"
                    className="mb-2 block text-sm font-medium text-indigo-900"
                  >
                    Lawyer Phone:
                  </label>
                  <div className="w-full">
                    <PhoneInput
                      country="de"
                      disableDropdown={false}
                      countryCodeEditable={false}
                      enableSearch
                      searchClass="rounded-lg border-gray-300 bg-gray-100 focus:border-blue-500 focus:outline-none"
                      placeholder="Enter Phone Number"
                      value={values?.lawyer_phoneNumber}
                      onChange={(phoneNumber: string) => {
                        setFieldValue("lawyer_phoneNumber", phoneNumber);
                      }}
                      onBlur={() => setFieldTouched("lawyer_phoneNumber")}
                    />
                  </div>
                  {touched?.lawyer_phoneNumber &&
                    errors?.lawyer_phoneNumber && (
                      <div className="text-sm text-red-500">
                        {errors?.lawyer_phoneNumber as string}
                      </div>
                    )}
                </div>
              </div>
              <hr className="my-6 border-t border-gray-300" />
              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={handleCancelEdit}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="border-transparent inline-flex items-center justify-center rounded-md border bg-green-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  Update Details
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <>
          <ViewLawyerDetails
            lawyerDetails={lawyerDetails}
            toggleEditMode={toggleEditMode}
          />
        </>
      )}
    </>
  );
};

export default LawyerDetails;
