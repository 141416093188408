import axiosInstance from "utils/services/api";

export const fetchAdminDetails = async (Id: string) => {
  try {
    if (Id) {
      const response = await axiosInstance.get(`admin/admin/${Id}`);
      return response?.data?.data;
    }
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateAdminDetails = async (Id: string, updatedData: any) => {
  try {
    const response = await axiosInstance.patch(
      `admin/admin/${Id}`,
      updatedData
    );
    return response;
  } catch (error) {
    throw error;
  }
};
