const UnderConstructionUI = () => {
  return (
    <div className="flex min-h-[78vh] flex-col items-center justify-center overflow-hidden">
      <div className="max-w-lg rounded-lg bg-gray-900 p-8 text-left shadow-lg">
        <h1 className="mb-4 text-3xl font-bold text-orange-400">
          Under Development
        </h1>
        <p className="text-md mb-6 text-left text-gray-600">
          We're working hard to bring you something awesome! Stay tuned and
          check back soon.
        </p>
        <div className="mb-5 flex items-center">
          <div className="mr-4 h-4 w-4 animate-bounce rounded-full bg-blue-500" />
          <div className="mr-4 h-4 w-4 animate-bounce rounded-full bg-yellow-500" />
          <div className="h-4 w-4 animate-bounce rounded-full bg-red-500" />
        </div>
        <p className="text-sm text-gray-600">
          Thank you for your patience. We'll be back soon!
        </p>
      </div>
    </div>
  );
};

export default UnderConstructionUI;
