import { dummyAvatar } from "assets";
import LoaderUI from "components/loader";
import ModalView from "components/modal";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaCheckCircle, FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import useDebounce from "utils/hooks/useDebounce";
import axiosInstance from "utils/services/api";
import { updateStudentById } from "./student.service";
import { IStudent } from "./student.types";

const StudentManagement = () => {
  // State variables
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [totalStudentCount, setTotalStudents] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [isShowAddressModal, setIsShowAddressModal] = useState(false);
  const [student, setStudent] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [searchClicked, setSearchClicked] = useState(false);

  const inputRef = useRef(null);

  const handleStudentSearch = async (e: any) => {
    const input = e?.target?.value.trim();
    setSearchQuery(input);

    if (input === "") {
      await fetchAllStudents();
      setSearchClicked(true);
      if (inputRef?.current) {
        inputRef?.current.focus();
      }
    }
  };

  useEffect(() => {
    if (searchClicked && inputRef?.current) inputRef?.current?.focus();
  }, [searchClicked, studentList]);

  const handleClearSearch = () => {
    setSearchQuery("");
    setSearchClicked(false);
    fetchAllStudents();
  };

  // Function to open the address modal
  const showDeleteModal = (student: IStudent) => {
    setIsDeleteModal(true);
    setStudent(student);
  };

  // Function to close the address modal
  const hideDeleteModal = () => {
    setIsDeleteModal(false);
    setStudent(null);
  };

  // Function to open the address modal
  const showAddressModal = (student: IStudent) => {
    setIsShowAddressModal(true);
    setStudent(student);
  };

  // Function to close the address modal
  const hideAddressModal = () => {
    setIsShowAddressModal(false);
    setStudent(null);
  };

  // Function to handle page change
  const handleChangePage = ({ selected }: { selected: number }) => {
    setCurrentPage(selected); // Increment selected by 1 because API page starts from 1
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(e?.target?.value)); // Set rows per page based on selected value
    setCurrentPage(0);
  };

  const handleViewStudentDetails = (studentId: string) => {
    if (studentId) {
      let url = `/admin/student-management/${studentId}`;
      navigate(url);
    }
  };

  const handleToggleActivation = async (
    studentId: string,
    isDeleted: boolean
  ) => {
    setLoading(true);
    try {
      const response = await updateStudentById(studentId, {
        isDeleted: !isDeleted,
      });
      await fetchAllStudents();
      toast.success(
        response?.message ||
          `${student?.name || "Student"} ${
            isDeleted ? "is now active" : "has been deactivated"
          } successfully!`
      );
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
      setIsDeleteModal(false);
    }
  };

  // Function to handle sorting when a column header is clicked
  const handleSortColumn = (columnName: string) => {
    if (sortBy === columnName) {
      // If the clicked column is already the sorting column
      // Toggle the sorting direction from ascending to descending or vice versa
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a new column is clicked for sorting
      setSortBy(columnName); // Set the sorting column to the clicked column
      setSortDirection("asc"); // Reset sorting direction to ascending
    }
  };

  // Inside the component
  const sortedStudentList = [...studentList]?.sort((a, b) => {
    if (sortBy) {
      if (sortBy === "visaExpiryDate") {
        // Sorting by visa expiry date
        const dateA = new Date(a[sortBy] || 0); // Convert to Date, default to 0 if invalid date
        const dateB = new Date(b[sortBy] || 0); // Convert to Date, default to 0 if invalid date
        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          // Ensure both dates are valid
          return sortDirection === "asc"
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        } else {
          return 0; // Return 0 if any date is invalid
        }
      } else {
        // Sorting by other columns
        if (a[sortBy] < b[sortBy]) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (a[sortBy] > b[sortBy]) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      }
    } else {
      return 0;
    }
  });

  const fetchAllStudents = useCallback(async () => {
    setLoading(true);
    let URL;
    if (debouncedSearchQuery) {
      URL = `/admin/student?page=${
        currentPage + 1
      }&limit=${rowsPerPage}&search=${debouncedSearchQuery}`;
    } else {
      URL = `/admin/student?page=${currentPage + 1}&limit=${rowsPerPage}`;
    }
    try {
      const response = await axiosInstance?.get(URL);
      if (response?.data?.data && response?.data?.data?.results?.length > 0) {
        setStudentList(response?.data?.data?.results);
        setTotalPageCount(response?.data?.data?.totalPages);
        setTotalStudents(response?.data?.data?.totalResults);
      } else {
        setStudentList([]);
      }
    } catch (error) {
      toast.error("Oops! Something Went Wrong Please Try Again later");
    } finally {
      setLoading(false);
    }
  }, [currentPage, rowsPerPage, debouncedSearchQuery]);

  useEffect(() => {
    fetchAllStudents();
  }, [fetchAllStudents, debouncedSearchQuery]);

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <>
      <div className="rounded-lg bg-white p-4 shadow-md">
        <div className="flex flex-col rounded-lg bg-gray-100 p-4 shadow-md md:flex-row md:justify-between">
          <h5 className="py-2 text-left text-xl font-semibold text-gray-900 md:order-1">
            Student List{" "}
            <span className="text-gray-600 md:ml-2">{totalStudentCount}</span>
          </h5>
          <div className="mt-4 w-full md:order-2 md:ml-4 md:mt-0 md:w-1/2">
            <div className="relative mt-4 md:ml-4 md:mt-0 md:flex-1">
              <input
                type="text"
                ref={inputRef}
                placeholder="Search University by Name or Email..."
                className="w-full rounded-lg border border-gray-300 bg-white px-10 py-2 transition duration-300 ease-in-out focus:border-blue-500 focus:outline-none"
                value={searchQuery}
                onChange={handleStudentSearch}
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-gray-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              {searchQuery && (
                <button
                  className="absolute inset-y-0 right-0 flex items-center justify-center px-2 focus:outline-none"
                  onClick={handleClearSearch}
                >
                  <RxCrossCircled className="mx-3 h-4 w-4 text-red-600" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="custom-scrollbar overflow-x-auto">
          <div className="scroll-container">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    className="cursor-pointer px-6 py-3 text-xs font-medium uppercase tracking-wider text-gray-500"
                    onClick={() => handleSortColumn("name")}
                  >
                    <span className="flex cursor-pointer items-center gap-2 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                      Name{" "}
                      {sortBy === "name" &&
                        (sortDirection === "asc" ? (
                          <FaSortUp />
                        ) : (
                          <FaSortDown />
                        ))}
                      {sortBy !== "name" && <FaSort />}
                    </span>
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Sign-up Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    University Approval
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Email
                  </th>{" "}
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Phone Number
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Study
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Visa Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Entry Visa Copy
                  </th>
                  <th
                    className="flex cursor-pointer items-center gap-2 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    onClick={() => handleSortColumn("visaExpiryDate")}
                  >
                    Visa Expiry Date{" "}
                    {sortBy === "visaExpiryDate" &&
                      (sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />)}
                    {sortBy !== "visaExpiryDate" && <FaSort />}
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Street Address
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    City
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedStudentList?.length > 0 ? (
                  sortedStudentList?.map((student: IStudent) => (
                    <tr key={student?.id}>
                      <td className="whitespace-nowrap px-10 py-2">
                        <div className="flex items-center gap-x-3">
                          <img
                            className="h-9 w-9 rounded-full object-cover"
                            src={student?.profilePic || dummyAvatar}
                            alt="user's pic"
                          />
                          <div>
                            <h2
                              className="cursor-pointer font-medium text-gray-800 hover:text-blue-500"
                              onClick={() =>
                                handleViewStudentDetails(student?.studentId)
                              }
                            >
                              {student?.name || "-"}
                            </h2>
                            <p className="text-sm text-gray-500">
                              {student?.studentId || "-"}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.signupStatus || "-"}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.status || "-"}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        <div className="flex items-center">
                          {student?.email}
                          {student?.isEmailVerified && (
                            <FaCheckCircle className="ml-2 text-green-500" />
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        <div className="flex items-center">
                          {student?.phoneNumber || "-"}
                          {student?.isPhoneNumberVerified && (
                            <FaCheckCircle className="ml-2 text-green-500" />
                          )}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.study || "-"}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.isGermanStudyEntryVisa
                          ? "German Study Entry Visa"
                          : ""}
                        {student?.isGermanStudyEntryVisa &&
                        student?.is90daysFreeEntry
                          ? ", "
                          : ""}
                        {student?.is90daysFreeEntry
                          ? "90-days free entry allowance"
                          : !student?.isGermanStudyEntryVisa &&
                            !student?.is90daysFreeEntry
                          ? " - "
                          : ""}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.entryVisaCopy ? (
                          <a
                            href={student?.entryVisaCopy}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline"
                          >
                            View Entry Visa
                          </a>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.visaExpiryDate
                          ? moment(student?.visaExpiryDate).format("YYYY-MM-DD")
                          : "-"}
                      </td>
                      {student?.streetAddress &&
                      student?.streetAddress?.length > 15 ? (
                        <td
                          className="cursor-pointer whitespace-nowrap px-6 py-2 text-blue-500 hover:underline"
                          onClick={() => showAddressModal(student)}
                        >
                          View Address
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-6 py-2">
                          {student?.streetAddress || "-"}
                        </td>
                      )}
                      <td className="whitespace-nowrap px-6 py-2">
                        {student?.city || "-"}
                      </td>
                      <td className="whitespace-nowrap px-6 py-2">
                        <button
                          onClick={() => showDeleteModal(student)}
                          className={
                            student?.isDeleted
                              ? "text-green-500 hover:text-green-700"
                              : "text-red-500 hover:text-red-700"
                          }
                        >
                          {student?.isDeleted ? "Activate" : "Deactivate"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={17}>
                      <div className="py-3 text-center text-gray-500">
                        No data available
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {studentList?.length > 0 && (
        <div className="flex items-center justify-between p-4">
          <select
            className="rounded-md border-gray-300 text-gray-900"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage} // Handle rows per page change
          >
            <option value={5}>5 per page</option>
            <option value={10}>10 per page</option>
            <option value={15}>15 per page</option>
            <option value={25}>25 per page</option>
          </select>
          {/* Pagination component */}
          <ReactPaginate
            pageCount={totalPageCount} // Total page count
            pageRangeDisplayed={3} // Number of pages to display
            marginPagesDisplayed={1} // Number of margin pages to display
            onPageChange={handleChangePage} // Handle page change
            containerClassName="pagination flex" // Container class name
            activeClassName="text-blue-500 font-semibold" // Active page class name
            pageClassName="mx-1"
            previousClassName="mx-1"
            nextClassName="mx-1"
            previousLabel="Previous"
            nextLabel="Next"
            forcePage={currentPage}
          />
        </div>
      )}
      <ModalView
        isOpen={isShowAddressModal}
        onClose={hideAddressModal}
        showActions={false}
      >
        <div className="p-4">
          <h2 className="text-2xl font-semibold text-gray-900">
            {student?.name}'s Address
          </h2>
          <p className="mt-2 text-gray-700">{student?.streetAddress}</p>
        </div>
      </ModalView>
      <ModalView
        isOpen={isDeleteModal}
        onClose={hideDeleteModal}
        onYesClick={() =>
          handleToggleActivation(student?.studentId, student?.isDeleted)
        }
        showActions={true}
      >
        <div className="p-4">
          <p className="text-md text-gray-900">
            Are You Sure Want to
            {student?.isDeleted ? " Activate" : " Deactivate"}{" "}
            <span className="font-semibold">{student?.name}</span> ?
          </p>
        </div>
      </ModalView>
    </>
  );
};

export default StudentManagement;
