import { FaUniversity } from "react-icons/fa";
import { FcBusinessman, FcHome, FcQuestions } from "react-icons/fc";
import { IoIosPerson } from "react-icons/io";
import { MdLock } from "react-icons/md";
import { PiUserDuotone } from "react-icons/pi";
import FAQManagement from "views/admin/FAQs";
import { ImUsers } from "react-icons/im";
import LawyerManagement from "views/admin/LawyerManagement";
import AdminProfile from "views/admin/Profile";
import StudentManagement from "views/admin/StudentManagement";
import StudentDetails from "views/admin/StudentManagement/UpdateStudentDetails";
import UniversityManagement from "views/admin/UniversityManagement";
import CreateNewUniversity from "views/admin/UniversityManagement/CreateUniversity";
import UniversityDetails from "views/admin/UniversityManagement/UpdateUniversityDetails";
import MainDashboard from "views/admin/default";
import ForgotPassword from "views/auth/ForgotPassword";
import ResetPassword from "views/auth/ResetPassword";
import SignIn from "views/auth/SignIn";
import CreateNewLawyer from "views/admin/LawyerManagement/CreateLawyer";
import LawyerDetails from "views/admin/LawyerManagement/UpdateLawyerDetails";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "default",
    icon: <FcHome className="h-6 w-6" />,
    component: <MainDashboard />,
    isPublic: true,
  },
  {
    name: "Student Management",
    layout: "/admin",
    path: "student-management",
    icon: <FcBusinessman className="h-6 w-6" />,
    component: <StudentManagement />,
    isPublic: true,
  },
  {
    name: "View Student Details",
    layout: "/admin",
    path: "student-management/:studentId",
    icon: <FcBusinessman className="h-6 w-6" />,
    component: <StudentDetails />,
    isPublic: false,
  },
  {
    name: "University Management",
    layout: "/admin",
    path: "university-management",
    icon: <FaUniversity className="h-6 w-6" />,
    component: <UniversityManagement />,
    isPublic: true,
  },
  {
    name: "View University Details",
    layout: "/admin",
    path: "university-management/:id",
    component: <UniversityDetails />,
    isPublic: false,
  },
  {
    name: "Create University",
    layout: "/admin",
    path: "university-management/create-university",
    component: <CreateNewUniversity />,
    isPublic: false,
  },
  {
    name: "View Lawyer Details",
    layout: "/admin",
    path: "lawyer-management/:id",
    component: <LawyerDetails />,
    isPublic: false,
  },
  {
    name: "Create Lawyer",
    layout: "/admin",
    path: "lawyer-management/create-lawyer",
    component: <CreateNewLawyer />,
    isPublic: false,
  },
  {
    name: "FAQs Management",
    layout: "/admin",
    path: "faq-management",
    icon: <FcQuestions className="h-6 w-6" />,
    component: <FAQManagement />,
    isPublic: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <PiUserDuotone className="h-6 w-6" />,
    component: <AdminProfile />,
    isPublic: true,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    isPublic: false,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <IoIosPerson className="h-6 w-6" />,
    component: <ForgotPassword />,
    isPublic: false,
  },
  {
    name: "reset",
    layout: "/auth",
    path: "reset-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ResetPassword />,
    isPublic: false,
  },
];
export default routes;
