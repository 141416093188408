import React, { useEffect } from "react";

interface IModalView {
  isOpen: boolean;
  onClose: () => void;
  onYesClick?: () => void;
  children: React.ReactNode;
  showActions?: boolean;
}

const ModalView = ({
  isOpen,
  onClose,
  onYesClick,
  children,
  showActions = true,
}: IModalView) => {
  useEffect(() => {
    const body = document.body;
    if (isOpen) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }

    return () => {
      body.classList.remove("modal-open");
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>

        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="relative inline-block max-h-[80vh] transform overflow-y-auto rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
          {showActions ? (
            // Render default modal content
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              {children}
            </div>
          ) : (
            <>
              <div className="bg-white px-2 pb-2 pt-3 sm:p-2 sm:pb-2">
                {children}
              </div>
              <div className="sticky bottom-0 w-full bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                <button
                  onClick={onClose}
                  type="button"
                  className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </>
          )}
          {showActions && (
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
              {onYesClick && (
                <button
                  onClick={onYesClick}
                  type="button"
                  className="border-transparent inline-flex w-full justify-center rounded-md border bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Yes
                </button>
              )}
              <button
                onClick={onClose}
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              >
                No
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalView;
