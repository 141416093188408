import { brandLogo } from "assets";
import { useFormik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import { PiSignInBold } from "react-icons/pi";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import FreezeUI from "react-loading";
import useAuthContext from "utils/hooks/useAuthContext";
import { SignInValidationSchema } from "./signin.schema";
import { adminLogin } from "./signin.services";
import { ISignInFormTypes } from "./signin.types";

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { setLoggedInUser } = useAuthContext();

  const handleFormSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await adminLogin(values);
      const adminUser = response?.data?.user;
      const accessToken = response?.data?.token?.access?.token;
      const admin_id = response?.data?.user?._id;

      const adminName = response?.data?.user?.name;

      setLoggedInUser(adminUser);
      localStorage.setItem("id", admin_id);
      localStorage.setItem("accessToken", accessToken);
      response?.message && toast.success(`Welcome ${adminName} !!`);
      navigate("/admin/default");
      resetForm();
    } catch (error: any) {
      toast?.error(error?.response?.data?.message || "Invalid Credentials");
    } finally {
      setIsLoading(false);
    }
  };

  const initialValues: ISignInFormTypes = {
    email: "",
    password: "",
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: SignInValidationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <form
      className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2"
      onSubmit={handleSubmit}
    >
      <div className="mt-[10vh] w-full max-w-[500px] flex-col items-center md:pl-4">
        <div className="form-container lg mx-auto w-full p-6 md:w-[500px]">
          <div className="mb-5 flex items-center justify-center text-center font-poppins text-[22px] font-bold uppercase text-navy-700">
            <img src={brandLogo} alt="logo" className="w-25 align-middle" />
          </div>
          <h4 className="mb-2.5 text-center text-4xl font-bold text-navy-700">
            Welcome Back!
          </h4>
          <p className="mb-9 ml-1 text-center text-base font-normal text-gray-600">
            Enter your email and password to sign in!
          </p>
          <div className="mb-3">
            <label htmlFor="email" className="text-sm text-navy-700 ">
              Email*
            </label>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="Enter Your Email"
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/* Display email_address validation error */}
            {errors?.email && touched?.email && (
              <div className="text-sm text-red-500 ">{errors?.email}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="text-sm text-navy-700">
              Password*
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                placeholder="***************"
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span
                className="absolute right-5 top-1/2 -translate-y-1/2 transform cursor-pointer text-gray-500"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <RiEyeLine /> : <RiEyeOffLine />}
              </span>
            </div>
            {errors?.password && touched?.password && (
              <div className="text-sm text-red-500 ">{errors?.password}</div>
            )}
          </div>
          <button
            className="linear theme-bg mt-2 flex w-full flex-row items-center justify-center rounded-xl py-[12px] text-center text-base font-medium text-white transition duration-200"
            type="submit"
            disabled={IsLoading}
          >
            {IsLoading ? (
              <FreezeUI type={"spin"} color={"#fff"} height={18} width={18} />
            ) : (
              <>
                <p>Sign In</p>
                <PiSignInBold className="ml-[5px]" />
              </>
            )}
          </button>
          <p className="my-3 text-center text-blue-800">
            <Link to="/auth/forgot-password">Forgot Password ?</Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignIn;
