import { ViewIcon } from "assets";
import WidgetCard from "components/widgetcard";
import { TiArrowBack } from "react-icons/ti";
import { useNavigate } from "react-router-dom";

const ViewLawyerDetails = ({
  lawyerDetails,
  toggleEditMode,
}: {
  lawyerDetails: any;
  toggleEditMode: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <>
      {lawyerDetails ? (
        <>
          <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
            <div className="bg-gray-200 p-6">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-semibold">
                  {lawyerDetails?.name || "Lawyer Details"}
                </h1>
              </div>
            </div>
            <div className="container mx-auto p-6">
              <div className="mt-4 grid gap-4 sm:grid-cols-1 md:grid-cols-3">
                <div className="sm:col-span-2 md:col-span-3">
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                    <WidgetCard
                      label="Name:"
                      data={
                        lawyerDetails?.name && (
                          <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                            {lawyerDetails?.name}
                          </span>
                        )
                      }
                    />
                    <WidgetCard
                      label="Email:"
                      data={
                        lawyerDetails?.email && (
                          <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                            {lawyerDetails?.email}
                          </span>
                        )
                      }
                    />
                    <WidgetCard
                      label="Phone Number:"
                      data={
                        lawyerDetails?.phoneNumber && (
                          <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                            {lawyerDetails?.phoneNumber}
                          </span>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-between">
                <button
                  className="border-transparent inline-flex items-center justify-center gap-2 rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={() => navigate("/admin/lawyer-management")}
                >
                  <span>Back</span>
                  <TiArrowBack className="h-4 w-4" />
                </button>
                <button
                  className="border-transparent inline-flex items-center justify-center gap-2 rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                  onClick={toggleEditMode}
                >
                  <span>Edit</span>
                  <ViewIcon />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="mx-auto mt-6 w-full overflow-hidden rounded-lg bg-white shadow-lg">
          <div className="p-6 text-center">
            <p className="text-red-500">No Data Found</p>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewLawyerDetails;
