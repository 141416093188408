import { dummyAvatar } from "assets";
import WidgetCard from "components/widgetcard";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { HiOutlineXCircle, HiPencilAlt } from "react-icons/hi";
import useAuthContext from "utils/hooks/useAuthContext";
import { AdminUpdateValidationSchema } from "./profile.schema";
import { fetchAdminDetails, updateAdminDetails } from "./profile.service";
import toast from "react-hot-toast";
import LoaderUI from "components/loader";

const AdminProfile = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { currentUser, setLoggedInUser } = useAuthContext();
  const [adminDetails, setAdminDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const getAdminDetails = useCallback(async () => {
    setLoading(true);
    try {
      if (currentUser?._id) {
        const updatedAdminDetailsResponse = await fetchAdminDetails(
          currentUser._id
        );
        setAdminDetails(updatedAdminDetailsResponse);
      }
    } catch (error) {
      console.error("Error fetching admin details:", error);
    } finally {
      setLoading(false);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?._id) {
      getAdminDetails();
    }
  }, [getAdminDetails, currentUser]);

  const initialValues = {
    admin_name: adminDetails?.name || "",
    admin_email: adminDetails?.email || "",
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      // Form Values
      const newData: any = {
        name: values?.admin_name,
        email: values?.admin_email,
      };

      //  Remove the Duplicate Values
      const updatedValues: any = {};

      for (const key in newData) {
        if (newData[key]?.trim() !== adminDetails[key]) {
          updatedValues[key] = newData[key];
        }
      }

      // API
      const response = await updateAdminDetails(
        adminDetails?._id,
        updatedValues
      );

      if (response?.data?.success) {
        setAdminDetails(response?.data?.data);
        toast.success(
          response?.data?.message || "Admin Details Updates Successfully"
        );
        setLoggedInUser(response?.data?.data);
      }
      resetForm();
      setIsEditMode(false);
    } catch (error) {
      toast.error("Oops, something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: AdminUpdateValidationSchema,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
  });

  if (loading) {
    return <LoaderUI />;
  }

  return (
    <div className="mt-5 w-full overflow-hidden rounded-lg bg-white shadow-lg">
      <div className="bg-theme p-6">
        <div className="mb-5 flex items-center justify-between">
          <h1 className="text-xl font-semibold text-white">Account Details</h1>
          <button onClick={handleEditClick} className="text-gray-900">
            {isEditMode ? (
              <HiOutlineXCircle className="h-6 w-6 text-white" />
            ) : (
              <HiPencilAlt className="h-6 w-6 text-white" />
            )}
          </button>
        </div>
        <div className="mt-2 flex h-24 w-full justify-center rounded-xl bg-cover">
          <div className="flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 ">
            <img
              className="h-full w-full rounded-full"
              src={dummyAvatar}
              alt=""
            />
          </div>
        </div>

        <div className="flex flex-col items-center">
          <h4 className="text-xl font-bold text-white">{adminDetails?.name}</h4>
          <p className="text-base font-normal text-white">Admin</p>
        </div>
      </div>
      <div className="p-6">
        {isEditMode ? (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="admin_name"
                className="mb-2 block text-sm font-semibold text-gray-700"
              >
                Username
              </label>
              <input
                type="text"
                name="admin_name"
                id="admin_name"
                className="w-full rounded-md border px-3 py-2 leading-tight text-gray-700 transition duration-300 focus:border-blue-500 focus:outline-none"
                placeholder="Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.admin_name}
              />
              {errors?.admin_name && touched?.admin_name && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.admin_name as string}
                  </div>
                </>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="admin_email"
                className="mb-2 block text-sm font-semibold text-gray-700"
              >
                Email address
              </label>
              <input
                type="email"
                name="admin_email"
                id="admin_email"
                className="w-full rounded-md border px-3 py-2 leading-tight text-gray-700 transition duration-300 focus:border-blue-500 focus:outline-none"
                placeholder="Email"
                value={values?.admin_email}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors?.admin_email && touched?.admin_email && (
                <>
                  <div className="text-sm text-red-500">
                    {errors?.admin_email as string}
                  </div>
                </>
              )}
            </div>
            <div className="mt-8 flex justify-end gap-3">
              <button
                type="button"
                className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={handleEditClick}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="border-transparent inline-flex items-center justify-center rounded-md border bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                Update Details
              </button>
            </div>
          </form>
        ) : (
          <div className="container mx-auto">
            <div className="mt-4 grid gap-4 sm:grid-cols-1 md:grid-cols-2">
              <div className="sm:col-span-2 md:col-span-3">
                <div className="grid grid-cols-1 gap-4 overflow-hidden md:grid-cols-2">
                  <WidgetCard
                    label="Name:"
                    data={
                      <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                        {adminDetails?.name}
                      </span>
                    }
                  />
                  <WidgetCard
                    label="Email:"
                    data={
                      <span className="inline-flex items-center justify-between gap-1 text-sm text-gray-900">
                        {adminDetails?.email}
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminProfile;
