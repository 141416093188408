import axiosInstance from "utils/services/api";

export const fetchLawyerById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`admin/admin/${id}`);
    return response?.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateLawyerById = async (
  id: string,
  updatedLawyerDetails: any
) => {
  try {
    const response = await axiosInstance.patch(
      `admin/lawyer/${id}`,
      updatedLawyerDetails
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

// Create Lawyer
export const createLawyer = async (lawyerDetails: any) => {
  try {
    const response = await axiosInstance.post(`admin/`, lawyerDetails);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
