import { useState } from "react";
import toast from "react-hot-toast";
import axiosInstance from "utils/services/api";
import { IFaqValues, IUpdateFAQ } from "./faq.types";

export const useFetchQuestions = () => {
  const [faqs, setFaqs] = useState([]);

  const fetchQuestionsFromAPI = async (
    category: string,
    subcategory: string
  ) => {
    try {
      const response = await axiosInstance.get(
        `/faq/faqs/${category}/${subcategory}`
      );
      const { success, data, message } = response?.data;

      if (success) {
        setFaqs(data);
      } else {
        setFaqs([]);
        toast.error(message || "An unknown error occurred");
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "An unknown error occurred";
      setFaqs([]);
      toast(errorMessage, {
        icon: "👋",
      });
    }
  };

  return { faqs, fetchQuestionsFromAPI };
};

export const deleteQuestionById = async (id: string) => {
  try {
    const response = await axiosInstance.delete(`/faq/faqs/${id}`);
    const { success, message } = response?.data;
    if (success) {
      toast.success(message || "Question Deleted Successfully");
    } else {
      toast.error(message || "An unknown error occurred");
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    toast.error(errorMessage);
  }
};

export const createNewQuestion = async (values: IFaqValues) => {
  try {
    const response = await axiosInstance.post("/faq/faqs/", values);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const updateQuestionById = async (id: string, faq: IUpdateFAQ) => {
  try {
    const response = await axiosInstance.put(`/faq/faqs/${id}`, faq);
    const { success, message } = response?.data;
    if (success) {
      toast.success(message || "Question Updated Successfully");
    } else {
      toast.error(message || "An unknown error occurred");
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || "An unknown error occurred";
    toast.error(errorMessage);
  }
};
