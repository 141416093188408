import { dummyAvatar } from "assets";
import { FcImport } from "react-icons/fc";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";

const Navbar = (props: {
  onOpenSidenav: () => void;
  brandText: string;
  secondary?: boolean | string;
  setIsLogoutModalOpen?: (isLogOutModalOpen: boolean) => void;
}) => {
  const { onOpenSidenav, brandText, setIsLogoutModalOpen } = props;

  const navigate = useNavigate();

  const handleLogoutButton = () => {
    setIsLogoutModalOpen(true);
    document.body.classList.add("modal-open");
  };

  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-center gap-4 rounded-xl bg-white/40 p-3 backdrop-blur-xl sm:justify-between">
      <div className="ml-[6px]">
        <p className="shrink text-[22px] capitalize text-theme">
          <Link to="#" className="font-bold capitalize ">
            {brandText}
          </Link>
        </p>
      </div>

      <div className="flex items-center justify-between gap-4">
        <div className="relative">
          <img
            src={dummyAvatar}
            alt="admin-profile"
            className="h-9 w-9 cursor-pointer rounded-full transition-shadow duration-300 hover:shadow-lg"
            onClick={() => navigate("/admin/profile")}
          />
        </div>
        <button
          onClick={handleLogoutButton}
          className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-800 text-gray-300 hover:bg-gray-700 focus:outline-none"
        >
          <FcImport className="h-5 w-5" />
        </button>

        {/* Hamburger Menu (for mobile) */}
        <div
          className="flex h-9 w-9 items-center justify-center rounded-full bg-gray-800 text-gray-300 hover:bg-gray-700 focus:outline-none xl:hidden"
          onClick={onOpenSidenav}
        >
          <GiHamburgerMenu className="h-5 w-5" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
