// Importing necessary dependencies and components from external files
import LoaderUI from "components/loader";
import { useFormik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import { FcPlus } from "react-icons/fc";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { CATEGORIES, SUBCATEGORIES } from "./faq.configs";
import { FAQValidationSchema } from "./faq.schemas";
import {
  createNewQuestion,
  deleteQuestionById,
  updateQuestionById,
  useFetchQuestions,
} from "./faq.services";
import { IFAQ } from "./faq.types";

const FAQManagement = () => {
  // State variables to manage selected category, subcategory, modal status, edit mode, and selected FAQ
  const [selectedCategory, setSelectedCategory] = useState("Study");
  const [selectedSubcategory, setSelectedSubcategory] = useState("Application");
  const [selectedCategoryForAddQuestion, setSelectedCategoryForAddQuestion] =
    useState("Study");
  const [
    selectedSubcategoryForAddQuestion,
    setSelectedSubcategoryForAddQuestion,
  ] = useState("Application");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const [loading, setLoading] = useState(false);

  // Custom hook to fetch FAQs from API
  const { faqs, fetchQuestionsFromAPI } = useFetchQuestions();

  // Event handler for category change
  const handleCategoryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e?.target;
    if (name === "category") {
      setSelectedCategory(value);
      setSelectedSubcategory(SUBCATEGORIES[value][0]);
    } else if (name === "subcategory") {
      setSelectedSubcategory(value);
    } else if (name === "categoryAddQuestion") {
      setSelectedCategoryForAddQuestion(value);
      setSelectedSubcategoryForAddQuestion(SUBCATEGORIES[value][0]);
    } else if (name === "subcategoryAddQuestion") {
      setSelectedSubcategoryForAddQuestion(value);
    }
  };

  // Event handler for deleting a question
  const handleDeleteQuestion = async (id: string) => {
    try {
      await deleteQuestionById(id);
      await fetchDataFAQ();
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };
  // Initial values for the form
  const initialValues: IFAQ = {
    category: selectedCategoryForAddQuestion,
    subCategoryName: selectedSubcategoryForAddQuestion,
    question: "",
    answer: "",
  };

  // Event handler for form submission
  const handleSubmitFAQ = async () => {
    try {
      if (!isEditMode) {
        const newQuestionData = {
          category: selectedCategoryForAddQuestion,
          subCategoryName: selectedSubcategoryForAddQuestion,
          questions: [
            {
              question: values?.question,
              answer: values?.answer,
            },
          ],
        };
        await createNewQuestion(newQuestionData);
        handleModalClose();
      } else {
        if (selectedFAQ?._id) {
          const updatePayload = {
            newQuestion: values?.question,
            newAnswer: values?.answer,
          };
          await updateQuestionById(selectedFAQ?._id, updatePayload);
          handleModalClose();
        }
      }
      fetchDataFAQ();
    } catch (error) {
      console.error("Error submitting FAQ:", error);
    }
  };

  // Formik hook for form handling
  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    touched,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: FAQValidationSchema,
    onSubmit: handleSubmitFAQ,
    enableReinitialize: true,
  });

  // Event handler for opening modal
  const handleModalOpen = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  // Event handler for closing modal
  const handleModalClose = () => {
    document.body.classList.remove("modal-open");
    setIsEditMode(false);
    setIsModalOpen(false);
    resetForm();
  };

  // Event handler for editing a question
  const handleEditQuestion = (faq: IFAQ) => {
    document.body.classList.add("modal-open");
    setIsEditMode(true);
    setIsModalOpen(true);
    setSelectedFAQ(faq);
    setFieldValue("question", faq?.question);
    setFieldValue("answer", faq?.answer);
  };

  const fetchDataFAQ = async () => {
    setLoading(true);
    try {
      await fetchQuestionsFromAPI(selectedCategory, selectedSubcategory);
    } catch (error) {
      console.error("Error fetching FAQs:", error);
    }
    setLoading(false);
  };

  // Fetch FAQs on component mount and when category or subcategory changes
  useEffect(() => {
    fetchDataFAQ();
  }, [selectedCategory, selectedSubcategory]);

  // JSX for rendering the component
  return (
    <div className="container mx-auto">
      {/* FAQs section */}
      <div>
        <div className="my-8">
          <div className="mb-6 flex flex-col items-center justify-between sm:text-center md:flex-row">
            <h1 className="mb-2 text-center text-2xl font-bold text-gray-800 md:mb-0 md:text-3xl">
              Frequently Asked Questions
            </h1>
            <button
              className="inline-flex items-center justify-center rounded-lg bg-indigo-600 px-4 py-2 text-white shadow-md transition-all duration-300 ease-in-out hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleModalOpen}
            >
              <span className="mr-2 flex items-center gap-2">
                Create New FAQ <FcPlus className="h-5 w-5" />
              </span>
            </button>
          </div>

          <div className="mb-4 rounded-lg border-l-4 border-yellow-400 bg-gray-100 p-4 shadow-md">
            <p className="font-semibold text-yellow-800">
              Welcome to our FAQs page!
            </p>
            <p className="text-gray-700">
              You can simply view the Different FAQs by Category, Feel free to
              add new FAQ. Simply click the "Create New FAQ" button.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label
                htmlFor="category"
                className="block font-semibold text-gray-700"
              >
                Select Category:
              </label>
              <select
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="focus:border-transparent w-full rounded-lg border bg-gray-100 p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {CATEGORIES.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="subcategory"
                className="block font-semibold text-gray-700"
              >
                Select Subcategory:
              </label>
              <select
                id="subcategory"
                name="subcategory"
                value={selectedSubcategory}
                onChange={handleCategoryChange}
                className="focus:border-transparent w-full rounded-lg border bg-gray-100 p-2 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {SUBCATEGORIES[selectedCategory]?.map((subcategory) => (
                  <option key={subcategory} value={subcategory}>
                    {subcategory}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {!loading ? (
            <div className="mt-8">
              {faqs?.length > 0 ? (
                faqs?.map((faq) => (
                  <div
                    key={faq?._id}
                    className="mb-6 flex flex-col justify-between rounded-lg bg-white p-6 shadow-md md:flex-row md:items-center"
                  >
                    <div className="mb-4 flex-grow md:mb-0 md:mr-4">
                      <h3 className="mb-2 text-xl font-semibold text-gray-800">
                        {faq?.question}
                      </h3>
                      <p className="text-gray-600">{faq?.answer}</p>
                    </div>
                    <div className="flex flex-col md:flex-row">
                      {/* Modernized edit button */}
                      <button
                        onClick={() => handleEditQuestion(faq)}
                        className="mb-2 mr-0 rounded-md border-2 border-purple-600 bg-gradient-to-r from-purple-600 to-pink-600 px-4 py-2 text-sm font-semibold text-white shadow-md transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2 md:mb-0 md:mr-2"
                      >
                        <span className="flex items-center space-x-2">
                          <FiEdit2 className="h-5 w-5" />
                          <span>Edit</span>
                        </span>
                      </button>

                      {/* Modernized delete button */}
                      <button
                        onClick={() => handleDeleteQuestion(faq?._id)}
                        className="rounded-md border-2 border-red-600 bg-gradient-to-r from-red-500 to-red-600 px-4 py-2 text-sm font-semibold text-white shadow-md transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        <span className="flex items-center space-x-2">
                          <FiTrash2 className="h-5 w-5" />
                          <span>Delete</span>
                        </span>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center text-gray-600">
                  No FAQs found for the selected category and subcategory.
                </p>
              )}
            </div>
          ) : (
            <LoaderUI />
          )}
        </div>
      </div>

      {/* Add/Edit FAQ Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-[#000] bg-opacity-60"></div>
          <div className="relative z-10 w-full max-w-lg overflow-hidden rounded-lg bg-white p-6 shadow-lg">
            <div className="mb-5 flex justify-between">
              <h1 className="border-b-2 border-gray-300 pb-2 text-2xl font-bold text-gray-800 sm:text-center md:text-3xl">
                {isEditMode ? "Update FAQ" : "Create New FAQ"}
              </h1>
            </div>
            {/* Dropdowns for selecting category and subcategory */}
            {!isEditMode && (
              <div className="md:grid-cols-13 my-2 grid grid-cols-1 gap-2">
                <select
                  name="categoryAddQuestion"
                  value={selectedCategoryForAddQuestion}
                  onChange={handleCategoryChange}
                  className="rounded-md border bg-gray-200 p-2 text-gray-700"
                >
                  {CATEGORIES.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>

                <select
                  name="subcategoryAddQuestion"
                  value={selectedSubcategoryForAddQuestion}
                  onChange={handleCategoryChange}
                  className="rounded-md border bg-gray-200 p-2 text-gray-700"
                >
                  {SUBCATEGORIES[selectedCategoryForAddQuestion]?.map(
                    (subcategory) => (
                      <option key={subcategory} value={subcategory}>
                        {subcategory}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
            {/* Form for adding/editing FAQ */}
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-3 overflow-y-auto">
                {/* Textarea for answer */}

                {/* Input field for question */}
                <div>
                  <input
                    type="text"
                    placeholder="Write New Question"
                    name="question"
                    className="w-full rounded-md border bg-gray-200 p-2 text-gray-700"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.question}
                  />
                  {/* Display question validation error */}
                  {errors?.question && touched?.question && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.question}
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <textarea
                    placeholder="Write Answer"
                    name="answer"
                    className="h-40 w-full rounded-md border bg-gray-200 p-2 text-gray-700"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.answer}
                  />
                  {/* Display answer validation error */}
                  {errors?.answer && touched?.answer && (
                    <>
                      <div className="text-sm text-red-500">
                        {errors?.answer}
                      </div>
                    </>
                  )}
                </div>

                {/* Buttons for form submission and cancellation */}
                <div className="flex items-center justify-between gap-3">
                  <button
                    type="submit"
                    className="w-full rounded bg-blue-500 px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-blue-600"
                  >
                    {isEditMode ? "Update" : "Create"}
                  </button>
                  <button
                    onClick={handleModalClose}
                    className="w-full rounded bg-red-500 px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-red-600"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// Exporting the component
export default FAQManagement;
