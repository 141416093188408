import UnderConstructionUI from "components/UnderConstructionUI";

const Dashboard = () => {
  return (
    <>
      <UnderConstructionUI />
    </>
  );
};

export default Dashboard;
