import React, { ReactNode } from "react";

interface IWidgetCard {
  label: string | ReactNode;
  data: string | ReactNode;
}
const WidgetCard = ({ data, label }: IWidgetCard) => {
  return (
    <>
      <div className="mb-4">
        <span className="block text-sm font-semibold text-gray-700">
          {label}
        </span>
        {data ? (
          <span className="block text-sm text-gray-900">{data}</span>
        ) : (
          <span className="block text-sm font-semibold text-red-500">
            No Data Found
          </span>
        )}
      </div>
    </>
  );
};

export default WidgetCard;
