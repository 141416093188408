export const ValidationMessages = {
  password: {
    required: "Password is required",
    invalid:
      "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#.",
  },
  confirmPassword: {
    required: "Password is required",
    invalid:
      "Password should be 8-14 characters with at least one lowercase, one uppercase, one number, and one special character from @$!%*?&#.",
  },
};
