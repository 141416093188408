import axiosInstance from "utils/services/api";
import { IUniversity } from "./university.types";

export const fetchUniversityById = async (id: string) => {
  try {
    const response = await axiosInstance.get(`admin/university/${id}`);
    return response?.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};

export const updateUniversityById = async (
  id: string,
  updatedUniversityDetails: IUniversity
) => {
  try {
    const response = await axiosInstance.patch(
      `admin/university/${id}`,
      updatedUniversityDetails
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const createUniversity = async (universityDetails: IUniversity) => {
  try {
    const response = await axiosInstance.post(`admin/`, universityDetails);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
