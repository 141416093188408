import { emailRegex } from "utils/constants";
import * as Yup from "yup";

export const AdminUpdateValidationSchema = Yup.object().shape({
  admin_name: Yup.string()
    .min(2, "Minimum 2 Characters")
    .max(20, "Maximum 20 Characters")
    .required("Name is required"),
  admin_email: Yup.string()
    .matches(emailRegex, "Invalid email address")
    .required("Email is Required"),
});
